// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";
// import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL, PROJECT_ID, STORAGE_BUCKET_ID, MESSAGING_SENDER_ID,FIREBASE_APP_ID, FIREBASE_MEASUREMENT_ID} 
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0zHdvL_ANdLV-gDiqYeQ_1v8D0Dq7Hts",
  authDomain: "resume-backend-38c74.firebaseapp.com",
  projectId: "resume-backend-38c74",
  storageBucket: "resume-backend-38c74.appspot.com",
  messagingSenderId: "871275091860",
  appId: "1:871275091860:web:2b9e47f84b285ff899a181"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
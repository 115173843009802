import React, { useEffect, useState } from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import Track from '../interfaces/Track'
import {db} from "../firebase"
import {addDoc, arrayUnion, collection, doc, FieldValue, getDoc, getDocs, updateDoc} from "firebase/firestore";
interface TrackListProps{
    tracks:Track[],
    trackIndex:number,
    setTrackIndex: React.Dispatch<React.SetStateAction<number>>
}

function TrackList({tracks,trackIndex, setTrackIndex}:TrackListProps):JSX.Element {
    const [streams, setStreams] = useState([0, 0, 0, 0, 0, 0, 0])

    async function getStreams(i:number){
        const documentRef = doc(db, "data","song" + i);
        const streams = await getDoc(documentRef);
        if(streams.exists()){
            let num = streams.data().streams;
            return num;
        }
        return 0
    }
    async function getInfo(){
        let array = streams
        for(var i =0; i < streams.length; i ++){
            array[i] =  await getStreams(i);
        }
        setStreams(array)
    }
    useEffect(() => {
        getInfo()
    }, [])
  return(
    <div className="songLContent"style={{width:"100%",display:"flex", flexWrap:"wrap",alignItems:"center",backgroundColor:"black", marginTop:150}}>
        {tracks.map((track, index) =>
            <div key={index} style={{width:"100%", display:"flex", flexWrap:"nowrap"}}>
                <button style={{width:"100%", border:"none",background:"none", color:"white", display:"flex", flexWrap:"nowrap", cursor:"pointer"}} onClick={() => setTrackIndex(index)}>
                    <div style={{width:"25%", height:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
                        <img className='' style={{height:60, width:60}} src={require("../assets/"+track.imageName)} alt="led" />
                    </div>
                    <div style={{textAlign:"left", width:"62.5%", display:"flex", alignItems:"center",flexWrap:"wrap", height:"100%", paddingLeft:2}}>
                        <div style={{width:"100%", display:"flex", alignItems:"center", flexWrap:"wrap", alignContent:"center"}}>
                            <div className="smallF darkB" style={{width:"100%", marginBottom:3}}>
                                {track.title}
                            </div>
                            <div className='xSmallF lightB' style={{width:"100%"}}>
                                {track.artist}
                            </div>
                        </div>
                    </div>
                    <div style={{width:"45%", display:"flex", alignItems:"center", justifyContent:"right", flexWrap:"wrap", alignContent:"center", height:"100%"}}>
                        <div className="smallF darkB" style={{width:"100%", justifyContent:"flex-end"}}>
                            Streams {streams[index]}
                        </div>
                    </div>
                </button>
                {/* <div style={{width:"20%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <AiOutlineHeart className="medButton" />
                </div> */}
            </div>
            )}
        </div>
    )
}
export default TrackList;
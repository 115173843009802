
import './TheIntro.css'
import './FontSizes.css'
import LedZepplin from './Led Zep.png'
import {AiOutlineHeart, AiFillStepBackward, AiFillStepForward, AiFillPlayCircle, AiFillPauseCircle, AiFillLinkedin, AiFillGithub, AiFillPhone, AiOutlinePhone, AiOutlineLink, AiOutlineMail, AiOutlineCloudDownload} from "react-icons/ai";
import {BiDotsHorizontalRounded} from "react-icons/bi";
import {FaHtml5, FaJava, FaPython, FaReact} from "react-icons/fa";
import {RiArrowDownSLine, RiArrowUpSLine} from "react-icons/ri";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {useSwipeable} from "react-swipeable";
import {IoIosArrowDown, IoIosArrowUp, IoIosMusicalNote} from "react-icons/io";
import {BsPause, BsPauseBtnFill, BsPlayFill, BsStopFill} from "react-icons/bs";
import {TbExternalLink} from "react-icons/tb"
import {DiJavascript1} from "react-icons/di"
import {SiAppstore} from "react-icons/si";
import Track from '../interfaces/Track';
import TrackList from '../components/TrackList';
import { time } from 'console';
import {db} from "../firebase"
import {addDoc, arrayUnion, collection, doc, FieldValue, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {storage} from "../firebase";
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { IKImage, IKVideo, IKContext, IKUpload } from 'imagekitio-react'
export default function TheIntro(){
    const collectionRef = collection(db, "data")

    // Components that will be shared a lot
    // a header ( will have a section to send an email to me, and a drop down with all my links)
    // the album picture
    // The title of song, author, and like (which will start a resume download)
    // A player 
    // Player will take in song, call backend and load song
    // Also player needs to be able to skip to next track, go back to previous song, and restart song
    // 
    // differences, each page needs own color scheme
    // 
    // How audio player will work in an ideal work not sure how to do this with react

    // We have how audio player componet that we create
    // it has all the functionality needed to go back skip take in current track
    // 
    const tracks:Track[] = [
        {
            title : "The Intro",
            artist : "Nick Wylie",
            songName : "redHotChilliPeppers.mp3",
            imageName: "ledZep.png",
            description:<div className="smallF" >
                Hello and welcome to my resume website.  I am Nick Wylie a computer science student graduating from the University of Missouri in May 2023 with a degree in computer science.  I have created this digital resume, as a fun and creative way to show my skills as a Software Developer.
                <br />
                <br /> 
                The music on the site is a collection of seven unique songs/riffs that I have composed on my guitar.  I have been playing guitar for over 5 years and it is something that always brings me joy.
                <br/>
                <br />
                <div>
                Each track has different information about my resume on the bottom of the screen.  Swiping or tapping on the top left of the screen will give you a library view of the resume, allowing for easy navigation of the site.  Swiping left and right on the album covers will change the song. 
                </div>
                <br />
                <div>
                    Currently I am seeking a Software Developer/Engineer job after my graduation in May 2023.  Please contact me with any questions.  
                </div>  
                </div>
                ,
            headerTitle:"About The Site"
        },
        {
            title : "The Skills",
            artist : "Nick Wylie",
            songName : "everythingStartsAtZero.mp3",
            imageName: "nicoAlbum.png",
            description:<div >
                <div className="xSmallF"><span className="largeF"><FaReact color="black" />  React</span> created this website with react.  2+ years experience using React on personal projects.</div>
                <div className="xSmallF"><span className="largeF"><FaJava color="black" />  Java</span> built REST API's during an internship at Enterprise Holdings, and 3 years experience during school.</div>
                <div className="xSmallF"><span className="largeF"><FaPython color="black" />  Python</span>  designed webscrapers in Python, created data analysis projects, and completed many practice interview coding questions.</div>
                <div className="xSmallF"><span className="largeF"><DiJavascript1  color="black" />  JavaScript</span> 3 years  for web development, and for solving coding questions.</div>
                <div className='xSmallF'><span className='largeF'><FaHtml5 color="black" /> Html/CSS</span> started out learning html and css to construct websites.  Have used css extensively on web/app projects.</div>
                <div className="xSmallF"><span className="largeF"><FaReact color="black" /> React Native</span> designed and created an iPhone app using React Native, have coded in the language for 1 year.</div>
            </div>,
            headerTitle:"Programming Languages"
            
        },
        {
            title : "The Projects, Pt.1",
            artist : "Nick Wylie",
            songName : "yourSecondBest.mp3",
            imageName: "pinkFloydAlbum.png",
            description: <div className="smallF">
                <div className='largeF darkB'>
                    Overview
                </div>
                <div >
                    I used this project to learn about app development.  It was also a great tool that I used to record and analyze my music practice sessions.
                </div>
                <div className='largeF darkB'>
                    Details
                </div>
                <div className="medF">
                    - Deployed to the IOS app store
                </div>
                <div className="medF">
                    - Recieved 350+ downloads
                </div>  
                <div className="medF">
                    - Reached #146 in paid music
                </div>
                <div className="medF">
                    - Allowed users to store detailed practice sessions with video, notes, song practiced, and quality of session stored.
                </div>  
                <div className="medF">
                    - Created with React Native
                </div>  
                <br />
                <br />
                <div className="largeF" >
                    Project Links <a target={"_blank"} href="https://apps.apple.com/us/app/music-practice-journal/id1612882454" style={{color:"white"}}> <SiAppstore  size={45} style={{background:"#174C7D", padding:5, borderRadius:10}}/></a>  <a href="https://github.com/nickblakewylie/Music-Practice-Journal" target={"_blank"} style={{color:"white" }}><AiFillGithub size={45} style={{background:"#174C7D", padding:5, borderRadius:10}} /></a>
                </div>
                </div>
                
                ,
            headerTitle: "Music Practice Journal"
        },
        {
            title : "The Projects, Pt.2",
            artist : "Nick Wylie",
            songName : "justSlowDown.mp3",
            imageName: "Beatles.png",
            description:<div className='smallF'>
                <div className='largeF darkB'>
                    Overview 
                 </div>
                Worked with a team of 4 interns to develop a mobile app prototype for an electric car sharing company.
                <br />
                <div className='largeF darkB'>
                    Details 
                 </div>
                <div className='medF'>
                    - Utilized Google API's, including Direction API, and Geocoder API
                </div>
                <div className='medF'>
                    - Built with React Native (frontend), and Firebase (backend)
                </div>
                <div className='medF'>
                    - Functionality includes directions to cars, viewing nearby charging stations, ride history, and searching for cities
                </div>
                <br/>
                <br />
                <div className='largeF'>
                    Project Links <a target={"_blank"} href="https://github.com/nickblakewylie/enterprise-web" style={{color:"white"}}><AiFillGithub  size={45} style={{backgroundColor:"#174C7D", padding:5, borderRadius:10}}/></a>
                </div>
            </div>,
            headerTitle:"Enterprise Web"
        },
        {
            title : "The Projects, Pt.3",
            artist : "Nick Wylie",
            songName : "catchyRiff.mp3",
            imageName: "elliottSmith.png",
            description:<div className="smallF">
                <div className='largeF darkB'>
                    Overview
                </div>
                Created a spin off of heardle.  Where users can select a random song by genre, and difficulty and have to guess the correct song in 30 seconds. 
                <br />
                <div className="largeF darkB">
                    Details
                </div>
                <div className="medF">
                    - Live chat to talk to other users implemented with Firebase
                </div>
                <div className="medF">
                    - Guess correct song with Spotify API - search 
                </div>
                <div className="medF">
                    - Authentication using Spotify API and Firebase
                </div>
                <br />
                <br />
                <div className='largeF'>
                    Project Links <a target={"_blank"} href="https://angularmvc-bc7d2.web.app" style={{color:"white"}}><TbExternalLink size={45} style={{backgroundColor:"#174C7D", padding:5, borderRadius:10}}/></a>
                </div>
            </div>,
            headerTitle:"B Side Website"
            
        },
        {
            title : "The Courses",
            artist : "Nick Wylie",
            songName : "theWrongSideOfTown.mp3",
            imageName: "okComputer.png",
            description: <div>   
                <div className="medF">
                    - Completed Algorigthm Design and Analysis 1,2 and Advanced
                </div>
                <div className="medF">
                    - Web Development 1 and 2
                </div>
                <div className="medF">
                    - Operating Systems
                </div>
                <div className="medF">
                    - Object Oriented Programming
                </div>
                <div className="medF">
                    - Database Management
                </div>
                <div className="medF">
                    - App Development
                </div>
                <div className="medF">
                    - Calculus 1, 2, 3 and math statistics
                </div>
            </div>,
            headerTitle: "Courses I have taken"
        },
        {
            title : "The End",
            artist : "Nick Wylie",
            songName : "originalSongInA.mp3",
            imageName: "chiliPeppers.png",
            description: <div>
                <div className="medF">
                    Thank you for visiting the website!
                </div>
                <br />
            </div>,
            headerTitle: "Thank you"
        }
     ]

    const config = {
            delta: 10,            
            preventDefaultTouchmoveEvent: true,                 // min distance(px) before a swipe starts. *See Notes*
            preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
            trackTouch: true,                      // track touch input
            trackMouse: true,                     // track mouse input
            rotationAngle: 0,                      // set a rotation angle
            swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
            touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
          
    }
    const topBarRefPassThrough = (el:any) => {
        topBarhanders.ref(el)
        topBarRef.current = el
    }
    const refPassthrough = (el:any) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);
        // set myRef el so you can access it yourself
       infoRef.current = el;
      }
    function velocityToDuration(velo:number){
        // usually between 0.2 - 5
        // best is about 0.6
        if(velo > 100){
            velo -= 100
        }
        let betterNum = (1000 / (Math.log10(velo * 60) + (velo / 4)));
        return betterNum 
    }
    // handlers for the draggable parts of the site
    const handlersToShowHome = useSwipeable({
        onSwiped:(eventData) => {
            if(eventData.dir === "Up"){
                // display animation to cause the bottom nav to 
                // slide up
                songListBottomRef.current.animate([
                    {'height': `150px`},
                    { 'height': `180%`}
                ], {
                    duration:velocityToDuration(eventData.velocity) +100,
                    easing: 'ease-in'
                });
                songListBottomRef.current.style.setProperty("height", "180%");
                // once the animation is complete hide the song list page
                // and show main page
                setTimeout(() => {
                    bigCRef.current.style.setProperty(
                        "overflow-y", "hidden"
                    ) 
                    songListBottomRef.current.style.setProperty("height", "150px");
                    songListRef.current.style.setProperty("z-index", "-100");
                    songListRef.current.style.setProperty(
                        "height","0px"
                    )
                    setShowingMainP(true);
                }, velocityToDuration(eventData.velocity) + 200);
                // here is the animation blur the main page
                // it is called a little later it is a quick animation
                setTimeout(() => {
                    mainContent.current.style.setProperty(
                        "display","flex"
                    )
                    mainContent.current.animate([
                        { 'filter':`blur(8px)`},
                        { 'filter': `none`}
                    ], {
                        duration: 700,
                        easing: 'ease-in'
                    })
                    setTimeout(() => {
                        mainContent.current.style.setProperty(
                            "filter", "none"
                        )
                    }, 1000)
                }, velocityToDuration(eventData.velocity) + 100);
        }},
        onTap:(eventData) => {
            if(eventData.event.type == "touchend"){
                return
            }
            songListBottomRef.current.animate([
                {'height': `150px`},
                { 'height': `180%`}
            ], {
                duration:1000 +100,
                easing: 'ease-in'
            });
            songListBottomRef.current.style.setProperty("height", "180%");
            // once the animation is complete hide the song list page
            // and show main page
            setTimeout(() => {
                bigCRef.current.style.setProperty(
                    "overflow-y", "hidden"
                ) 
                songListBottomRef.current.style.setProperty("height", "150px");
                songListRef.current.style.setProperty("z-index", "-100");
                songListRef.current.style.setProperty(
                    "height","0px"
                )
                setShowingMainP(true);
            }, 1300);
            // here is the animation blur the main page
            // it is called a little later it is a quick animation
            setTimeout(() => {
                mainContent.current.style.setProperty(
                    "display","flex"
                )
                mainContent.current.animate([
                    { 'filter':`blur(8px)`},
                    { 'filter': `none`}
                ], {
                    duration: 700,
                    easing: 'ease-in'
                })
                setTimeout(() => {
                    mainContent.current.style.setProperty(
                        "filter", "none"
                    )
                }, 1000)
            }, 1000 + 100);
        },
        ...config
    })
    // handlers for the more info part
    const handlers = useSwipeable({
        onSwipeStart: (eventData) => {
        },
        onSwiped: (eventData) => {
            if(eventData.dir === "Down"){
                infoRef.current.animate([
                    { marginBottom: `75vh` },
                    { marginBottom: `0` },
                ], {
                    duration:velocityToDuration(eventData.velocity),
                    easing: 'ease-in',
                    animationFillMode:"forward"
                });
                infoRef.current.style.setProperty(
                    "margin-bottom",  "0vh"
                )
                setShowInfo(false)
            }else if(eventData.dir ==="Up"){
                incrementMoreInfoClicks()
                // infoRef.className = "moreInfoAfter "
                infoRef.current.animate([
                    { marginBottom: `0` },
                    { marginBottom: `75vh` },
                ], {
                    duration:velocityToDuration(eventData.velocity),
                    easing: 'ease-in',
                    animationFillMode:"forward"
                });
                infoRef.current.style.setProperty(
                    "margin-bottom",  "75vh"
                )
                // infoRef.cu
                setShowInfo(true);
            }
        },
        onTap:(eventData) => {
            // setShowInfo(!showInfo);
            if (eventData.event.type === "touchend"){
                return
            }
            if(showInfo){
                infoRef.current.animate([
                    { marginBottom: `75vh` },
                    { marginBottom: `0` },
                ], {
                    duration:1000,
                    easing: 'ease-in',
                    animationFillMode:"forward"
                });
                infoRef.current.style.setProperty(
                    "margin-bottom",  "0vh"
                )
                setShowInfo(false)
            }else{
                incrementMoreInfoClicks()
                // infoRef.className = "moreInfoAfter "
                infoRef.current.animate([
                    { marginBottom: `0` },
                    { marginBottom: `75vh` },
                ], {
                    duration:1000,
                    easing: 'ease-in',
                    animationFillMode:"forward"
                });
                infoRef.current.style.setProperty(
                    "margin-bottom",  "75vh"
                )
                // infoRef.cu
                setShowInfo(true);
            }
        },
        ...config,
      });
    
    const topBarhanders = useSwipeable({
        onSwiped:(eventData) =>{
            if(eventData.dir === "Down"){
                setShowingMainP(false);
                songListRef.current.animate([
                    { height: `0`},
                    { height: `100%`}
                ], {
                    duration:velocityToDuration(eventData.velocity) + 100 ,
                    easing: 'ease-in',
                    animationFillMode:"forward",
                    
                }) 
                setTimeout(() => {
                    songListRef.current.style.setProperty(
                        "height","100%"    
                    )
                    mainContent.current.style.setProperty(
                        "display","none"
                    )
                    bigCRef.current.style.setProperty(
                        "overflow-y", "scroll"
                    )
                    songListRef.current.style.setProperty("z-index", "0");
                }, velocityToDuration(eventData.velocity) - 10)
                // mainContent.current.animate([
                //     { marginTop: `0`},
                //     { marginTop: `100vh`},
                // ], {
                //     duration:1000,
                //     easing: 'ease-in',
                //     animationFillMode:"forward"
                // });
            }
        },
        onTap:(eventData) => {
            if (eventData.event.type === "touchend"){
                return
            }
            setShowingMainP(false);
            songListRef.current.animate([
                { height: `0`},
                { height: `100%`}
            ], {
                duration:1000 ,
                easing: 'ease-in',
                animationFillMode:"forward",
                
            }) 
            setTimeout(() => {
                songListRef.current.style.setProperty(
                    "height","100%"    
                )
                mainContent.current.style.setProperty(
                    "display","none"
                )
                bigCRef.current.style.setProperty(
                    "overflow-y", "scroll"
                )
                songListRef.current.style.setProperty("z-index", "0");
            }, 990)
        },
        ...config
    })

    const albumSwipeHelper = useSwipeable({
        onSwipedLeft:(eventData) => {
            nextSong()
        },
        onSwipedRight:(eventData) => {
            previousSong()
        },
     ...config   
    })
    function tappingMoreInfo(){
    }
    var myPlayer =useRef( new Audio());
    var progressBar:any = useRef();
    // const [currentTrack, setCurrentTrack] = useState(0);
    const [showLinks, setShowLinks] = useState(false);
    const [timeListened, setTimeListened] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [songDuration, setSongDuration] = useState(0);
    const [songIsPlaying, setSongIsPlaying] =useState(false);
    const [songLoaded, setSongLoaded] = useState(false);
    const [trackIndex, setTrackIndex] = useState(0);
    const [showInfo, setShowInfo] = useState(false);
    const [infoPos, setInfoPos] = useState(0);
    const [showingMainP, setShowingMainP] = useState(true);
    const animationRef:any = useRef();
    const albumRef:any = useRef();
    const infoRef: any = useRef();
    const topBarRef:any = useRef();
    const mainContent:any = useRef()
    const songListRef:any = useRef();
    const bigCRef:any= useRef();
    const songListBottomRef:any = useRef();
    const [songListRangePercent, setSongListRangePercent] = useState("0%");
    function previousSong(){
        animateForward();
        setTimeout(() => {
            let newIndex  = Math.abs((trackIndex + tracks.length - 1) % tracks.length);
            setTrackIndex(newIndex);
            setSongDuration(0);
            myPlayer.current.src = require("../assets/" + tracks[newIndex].songName);
            myPlayer.current.currentTime = 0;
            progressBar.current.value = myPlayer.current.currentTime;
            changePlayerCurrentTime();
            forwardToCenter();
        }, 300);
        // setSongIsPlaying(false);
    }
    function animateForward(){
        albumRef.current.className = "homeAlbumCover albumCoverForward";
    }
    function forwardToCenter(){
        albumRef.current.className = "homeAlbumCover albumCoverForwardToCenter";
    }
    function animateBack(){
        albumRef.current.className = "homeAlbumCover albumCoverBack";
    }
    function backToCenter(){
        albumRef.current.className = "homeAlbumCover albumCoverBackToCenter";
    }
    function nextSong(){
        animateBack();
        setTimeout(() => {
        let newIndex  = (trackIndex + 1) % tracks.length;
        setTrackIndex(newIndex);
        setSongDuration(0);
        myPlayer.current.src = require("../assets/" + tracks[newIndex].songName);
        myPlayer.current.currentTime = 0;
        progressBar.current.value = myPlayer.current.currentTime;
        changePlayerCurrentTime();
        backToCenter();
        }, 300);
        // setSongIsPlaying(false);
    }
    const changeRange = () => {
        myPlayer.current.currentTime = progressBar.current.value;
        changePlayerCurrentTime();
    };
    // returns number 1 - 100
    function calculatePercentOfSongPlayed(){
        if(myPlayer.current.duration != null && myPlayer.current.duration !== 0){
            return Math.floor((myPlayer.current.currentTime/ myPlayer.current.duration) * 100);
        }else{
            return 0;
        }
    }
    const changeSongListRange = () => {
        setSongListRangePercent(calculatePercentOfSongPlayed().toString() + "%");
    }
    const changePlayerCurrentTime = () => {
        progressBar.current.style.setProperty(
            "--seek-before-width",
            `${(progressBar.current.value / myPlayer.current.duration) * 100}%`
        );
        setCurrentTime(progressBar.current.value);
    };
    function timeToString(timeNumber:number){
        timeNumber = Math.round(timeNumber)
        var min = 0;
        var sec = 0;
        if(timeNumber> 60){
            min = Math.floor(timeNumber/60);
            sec = timeNumber - (min *60);
        }else{
            sec = timeNumber
        }
        if(sec < 10){
            return min.toString() +":0" + sec.toString()
        }else{
            return min.toString() + ":" + sec.toString();
        }
    }
    async function incrementStreams(){
        const documentRef = doc(db, "data","song" + trackIndex);
        const streams = await getDoc(documentRef);
        if(streams.exists()){
            updateDoc(documentRef, {streams: streams.data().streams + 1})
        }
    }
    async function incrementMoreInfoClicks(){
        const documentRef = doc(db, "data","moreInfo");
        const clicks = await getDoc(documentRef);
        if(clicks.exists()){
            updateDoc(documentRef, {clicks: clicks.data().clicks + 1})
        }
    }
    const whilePlaying = () =>{
        progressBar.current.value = myPlayer.current.currentTime;
        changeSongListRange()
        changePlayerCurrentTime();
        animationRef.current = requestAnimationFrame(whilePlaying)
    }
    function playOrStop(){
        if(!songIsPlaying){
            setSongIsPlaying(true);
            myPlayer.current.play()
            animationRef.current = requestAnimationFrame(whilePlaying)
        }else{
            setSongIsPlaying(false)
            myPlayer.current.pause();
            cancelAnimationFrame(animationRef.current)
        }
    }
    function onlyPlay(){
        setSongIsPlaying(true);
        myPlayer.current.play()
        animationRef.current = requestAnimationFrame(whilePlaying)
    }
    useEffect(() => {
        setTimeout(() => {
            const seconds = Math.floor(myPlayer.current.duration);
            setSongDuration(seconds);
            progressBar.current.max = seconds;
        }, 200);
    }, [songLoaded, myPlayer?.current?.readyState]);

  useEffect(() => {
    if(songIsPlaying == true){
        myPlayer.current.currentTime = 0;
        setSongIsPlaying(false);
        // continue and play the next track
        onlyPlay();
    }
  }, [trackIndex])

  const [randomTime, setRandomTime] = useState(Math.floor(Math.random() * (20 - 10) + 10 ));
  useEffect(() => {
    if(currentTime == randomTime){
        // call api to increment listens for the track
        incrementStreams()
    }
    if(songDuration != null && songDuration > 0 && currentTime > 0  && currentTime == (Math.floor(songDuration))){
        nextSong();
    }
  }, [currentTime])

  async function updateResumeDownloads(){
    const documentRef = doc(db, "data","resumeDownloads");
    const downloads = await getDoc(documentRef);
    if(downloads.exists()){
        updateDoc(documentRef, {downloads: downloads.data().downloads + 1})
    }
  }
  function getAudioFile(audioName:string){
    const s = getStorage();
    const storageRef = ref(s, audioName)
    getDownloadURL(storageRef).then((url) => {
        console.log(url)
        return url
    }).catch((error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
        return ""
    });
    return "hello"
  }
    return(
        <div ref={bigCRef} className="bigContainer" >
        <div ref={songListRef} className="songLSmallContainer">
            <div style={{position:"relative", display:"flex", flexWrap:"wrap", width:"100%", height:"100%",backgroundColor:"black", alignItems:"flex-start"}}>
                <div className="smallF" style={{width:"100%",position:"fixed",  background: "linear-gradient(0deg, rgba(0, 194, 255, 0.5544) -75.4%, rgba(217, 217, 217, 0) 169.05%), rgba(0, 0, 1, 1)", height:120, display:"flex", alignItems:"center",justifyContent:"center"}}>
                   Nick's Resume
                </div>
                <TrackList tracks={tracks} trackIndex={trackIndex} setTrackIndex={setTrackIndex}/>

                <div ref={songListBottomRef} className="songListB" style={{width:"100%", position:"fixed",bottom:0,display:"flex", flexWrap:"wrap", alignContent:"start"}}>
                    <div style={{width:"100%", height:100, display:"flex", flexWrap:"nowrap", backgroundColor:"#284C4C"}}>
                            <div style={{width:"20%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <img style={{height:60, borderRadius:5}}  src={require("../assets/"+tracks[trackIndex].imageName)} alt="led" />
                            </div>
                            <div style={{textAlign:"left", width:"50%", display:"flex", alignItems:"center",flexWrap:"wrap"}}>
                                <div style={{width:"100%"}}>
                                    <div className="medF darkB">
                                        {tracks[trackIndex].title}
                                    </div>
                                    <div className="xSmallF lightB">
                                        {tracks[trackIndex].artist}
                                    </div>
                                </div>
                            </div>
                            <div style={{width:"20%", display:"flex", alignItems:"center"}}>
                            </div>
                            <div style={{width:"20%",display:"flex", justifyContent:"center", alignItems:"center"}} >
                                <button style={{padding:0,border:"none",width:"100%",height:"100%",background:"none", color:"white",  cursor:"pointer",zIndex:1}} onClick={() => playOrStop()} >
                                { songIsPlaying ? 
                                    <BsPause size={50} color="white"/>: <BsPlayFill size={50} color="white"/>}
                                </button>
                            </div>
                    </div>
                    <div style={{width:"100%", backgroundColor:"black", height:5}}>
                        <div style={{width:songListRangePercent, backgroundColor:"white", height:2}}></div>
                    </div>
                    <div {...handlersToShowHome} style={{width:"100%", height:50, display:"flex",justifyContent:"center", flexWrap:"wrap", cursor:"-webkit-grab"}}>
                            <div style={{width:"100%",margin:0,padding:0}}>
                                <RiArrowUpSLine size={35} color="white" />
                                <div style={{width:"100%", fontSize:10, marginTop:-10}}>
                                swipe/tap
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={showLinks?"linksContainer":"hideLinks"} style={{overflow:"hidden"}} >
            <div className='responsiveW'>
            <div style={{width:"100%", textAlign:"right", marginTop:10,color:"white"}}>
                <button style={{padding:0,border:"none",margin:0,background:"none", color:"white",  cursor:"pointer"}} onClick={() =>setShowLinks(false)} ><h1 style={{fontSize:40, textAlign:"center"}}>X</h1></button>
            </div>
           <div className='largeF' style={{marginTop:0,color:"white"}}>
            Links
           </div>
           <div style={{textAlign:"left"}}>
                <a target={"_blank"} href="mailto:nw5pp@mail.missouri.edu?subject=Resume Website&body=I saw your resume website." style={{width:"100%", background:"none",border:"none", cursor:"pointer", color:"white", marginTop:50, display:"flex", alignItems:"center"}}>
                    <div>
                        <AiOutlineMail size={40} />
                    </div>
                    <div className="largeF" style={{marginTop:10, marginLeft:20}}>
                        Email
                    </div>
                </a>
                <a target={"_blank"} href="https://www.linkedin.com/in/nick-wylie-952137249/" style={{width:"100%", background:"none",border:"none", cursor:"pointer", color:"white", marginTop:50, display:"flex", alignItems:"center"}}>
                    <div>
                        <AiFillLinkedin size={40}/> 
                    </div>
                    <div className="largeF" style={{marginTop:10, marginLeft:20}}>
                        LinkedIn
                    </div>
                </a>
                <a target={"_blank"} href="https://github.com/nickblakewylie" style={{width:"100%", background:"none",border:"none", cursor:"pointer", color:"white", marginTop:50, display:"flex", alignItems:"center"}}>
                    <div>
                        <AiFillGithub size={40} />
                    </div>
                    <div className="largeF"  style={{marginTop:10, marginLeft:20}}>
                        Github
                    </div>
                </a>
                <a target={"_blank"} href="https://www.instagram.com/by_unpopular_demand_music/?hl=en" style={{width:"100%", background:"none",border:"none", cursor:"pointer", color:"white", marginTop:50, display:"flex", alignItems:"center"}}>
                    <div>
                        <IoIosMusicalNote size={40} />
                    </div>
                    <div className="largeF" style={{marginTop:10, marginLeft:20}}>
                        Music
                    </div>
                </a>
           </div>
           </div>
        </div>
        <div ref={mainContent} className={"songBackground " + "songBackground"+trackIndex} style={{overflow:"hidden"}}>
            <div className="secondContainer" style={{marginTop:infoPos}}>
            <div ref={topBarRefPassThrough} className="header" style={showingMainP?{display:"flex"}:{display:"none"}}>
                <div {...topBarhanders} style={{width:"50%", display:"flex", justifyContent:"flex-start", flexWrap:"wrap", alignItems:"center", cursor:"-webkit-grab"}}>
                    <div className="xSmallF" style={{width:"100%", display:"flex", justifyContent:"flex-start"}}>
                        swipe/tap
                    </div>
                    <div style={{width:"100%", display:"flex", justifyContent:"flex-start"}}>
                        <RiArrowDownSLine className='largeButton' color="white" />
                    </div>
                </div>
                <div className="smallF" style={{width:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    Nick's Resume
                </div>
                <div style={{width:"50%",display:"flex",justifyContent:"flex-end"}}>
                    <button style={{padding:0,border:"none",margin:0,background:"none", color:"white",  cursor:"pointer"}} onClick={() =>setShowLinks(true)}>
                        <BiDotsHorizontalRounded className='largeButton' color="white" />
                    </button>
                </div>
            </div>
            <div  className="songContent" >
                <div {...albumSwipeHelper} className="grabbable">
                <img  className="homeAlbumCover" ref={albumRef} src={require("../assets/"+tracks[trackIndex].imageName)} alt="led" />
                </div>
                <div className="songName">
                    <div style={{width:"100%", display:"flex", flexWrap:"nowrap",marginBottom:-20}}>
                        <div className='largeF darkB' style={{width:"70%",textAlign:"left"}}>
                            {tracks[trackIndex].title}
                        </div>
                        <div style={{width:"30%",textAlign:"end"}}>
                            <a href={require("../assets/Resume.pdf")} download="Resume.pdf" target="_blank"  style={{color:"white"}} onClick={() => updateResumeDownloads()}>
                            <AiOutlineCloudDownload className="largeButton"/>
                            </a>
                        </div>
                    </div>
                    <div className="smallF lightB" style={{width:"100%", textAlign:"start"}}>
                        {tracks[trackIndex].artist}
                    </div>
                </div>
                <div className="playerSlider">
                    <div style={{ width:"100%"}}>
                        {/* Put my custom slider here */}
                        <input
                            type="range"
                            className="progressBar"
                            defaultValue="0"
                            ref={progressBar}
                            onChange={changeRange}
                        />
                    </div>
                    <div style={{display:"flex", flexWrap:"nowrap"}}>
                            <div className="xSmallF" style={{fontWeight:10,marginTop:10,marginLeft:0}}>
                                {timeToString(currentTime)}
                            </div>
                    </div>
                    <div style={{width:"100%",textAlign:"end"}}>
                        <div className="xSmallF" style={{fontWeight:10, marginTop:-10}}>
                                {songDuration != null && !isNaN(songDuration) && songDuration !== 0? timeToString(songDuration) : "-:--"}
                        </div>
                    </div>
                    <audio className="myBackgroundVid" 
                        ref={myPlayer} src={require("../assets/" + tracks[trackIndex].songName)}
                        // ref={myPlayer} src={tracks[trackIndex].songName)}
                        // preload="auto"
                        onLoadedMetadata={() => setSongLoaded(true)}
                        />
                    <div style={{width:"100%", display:"flex",flexWrap:"nowrap", justifyContent:"space-around", marginTop:20}}>
                        <button style={{padding:0,border:"none",margin:0, borderRadius:1000,background:"none", color:"white", cursor:"pointer"}} onClick={( ) => previousSong()}>
                            <AiFillStepBackward className="medButton"  />
                        </button>
                        <button style={{padding:0,border:"none",margin:0, borderRadius:1000,background:"none", color:"white",  cursor:"pointer"}} onClick={( ) => playOrStop()}>
                            {songIsPlaying? 
                                <AiFillPauseCircle className='bigButton' /> :
                            <AiFillPlayCircle className="bigButton"/>
                            }
                        </button>
                        <button style={{padding:0,border:"none",margin:0, borderRadius:1000,background:"none", color:"white",  cursor:"pointer"}} onClick={( ) => nextSong()}>
                        <AiFillStepForward className="medButton" />
                        </button>
                    </div>
                </div>
                </div>
                </div>
                <div ref={refPassthrough} className={"moreInfo " + "moreInfo" + trackIndex + " " + (showInfo ?"showTheInfo" : "") + " "} style={showingMainP?{}:{display:"none"}} >
                    <div {...handlers}  style={{paddingLeft:5,paddingTop:15,width:"100%", textAlign:"left",display:"flex", alignItems:"center", flexWrap:"nowrap", color:"white", marginTop:-15, cursor: "-webkit-grab"}}>
                        <div style={{ marginRight:5, textAlign:"center"}}>
                            <div>
                            { showInfo?
                            <IoIosArrowDown className="medButton" color="white" />:
                            <IoIosArrowUp className="medButton" color="white"/> 
                            }
                            </div>
                            <div className="xSmallF">
                                swipe/tap
                            </div>
                        </div>
                        <div className="medF"> 
                            {tracks[trackIndex].headerTitle}
                        </div>
                    </div>
                    <div style={{marginTop:0, width:"100%%",paddingLeft:5,paddingRight:5, paddingTop:30}}>
                        <div style={{wordBreak:"break-word", width:"100%", fontSize:15, lineHeight:2, textAlign:"left"}}>
                            {tracks[trackIndex].description}
                        </div>
                    </div>
                </div>
            <div>
                <video autoPlay muted loop playsInline className='myBackgroundVid' style={!showingMainP ? {display: "none"}: {}}  src="https://ik.imagekit.io/4id4r6cmsz/videoFile/o/resumeBackgroundVideo.mp4?alt=media&token=a99deeda-fd31-4689-8659-20c7086bf304" />
                    {/* <source src={require("../assets/BackgroundMovie.mov")} type="video/mp4" />
                </video> */}
            </div>
        </div>
        </div>
    )
}
import { useState } from 'react';
import './App.css';
import TheIntro from './screens/TheIntro';
function App() {
  // I need to have all my different screens
  // also a way to be able to change songs
  // 
  const [currentScreen, setCurrentScreen] = useState(0);
  return (
    <div className="App">
        <TheIntro />
      {/* <button onClick={() => setCurrentScreen(1)}>Change screen</button> */}
    </div>
  );
}

export default App;
